#frontpageContainer {
    font-family: Garamond, sans-serif;
    font-weight: 300;
    width: 100%;
    margin-bottom: -500px;
  }
#video {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    margin-bottom: -300px;
    padding-bottom: -300px;
}
#frontpageContent {
    width: 55vw;
    height: min-content !important;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 233px;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    text-align: left;
    background-color: rgba(85,128,169,1);
    color: #fff;
    padding: 55px 34px 55px 34px;
    border-radius: 21px;
    box-shadow: 0px 0px 5px rgba(85,128,169,1);
}
#frontpageContent p {
    font-size: 18px;
}
#frontpageContent h2 {
    margin-top: 34px;
}
@media screen and (max-width: 900px) {
    #video {
        display: none !important;
    }
    #frontpageContent {
        width: 89vw !important;
    }
    
}
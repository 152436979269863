.servicesLists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.servicesTitle {
    width: max-content;
    padding: 13px 34px 13px 34px;
}
.servicesList {
    width: 100%;
    justify-content: stretch;
}
@media screen and (min-width: 900px) {
    .servicesList {
        width: 30% !important;
    }
}
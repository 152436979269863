@font-face {
  font-family: Kiona-Regular;
  src: url(../public/fonts/Kiona-Regular.ttf);
}
@font-face {
  font-family: Kiona-SemiBold;
  src: url(../public/fonts/Kiona-SemiBold.ttf);
}
@font-face {
  font-family: Kiona-Bold;
  src: url(../public/fonts/Kiona-Bold.ttf);
}
@font-face {
  font-family: Raleway;
  src: url(../public/fonts/Raleway-VariableFont_wght.ttf);
}
body {
  margin: 0;
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-weight: 200;
  background-color: rgba(85,128,169,1) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rightJustify {
  text-align: right;
  margin-right: 20px;
}
.xs {
  font-size: .55em;
}
.sm {
  font-size: .8em;
}
.md {
  font-size: 1em;
}
.lg {
  font-size: 1.25em;
}
.xl {
  font-size: 1.5em;
}
.xxl {
  font-size: 1.75em;
}
.xxxl {
  font-size: 2em;
}
.regular {
  font-weight: 400;
}
.semiBold {
  font-weight: 600;
}
.bold {
  font-weight: 800;
}
.centerJustify {
  text-align: center;
}
.leftJustify {
  text-align: left;
}
.rightJustify {
  text-align: right;
}
.brandPadding {
  padding: 13px 21px 13px 21px;
}
.MuiButton-containedPrimary {
  background-color: rgba(99,53,81,1) !important;
}
.MuiButton-containedPrimary:hover {
  background-color: rgba(83,86,131,1) !important;
}

.pageContainer {
  height: min-content;
  font-family: Raleway, sans-serif;
  font-weight: 300;
  padding-top: 89px;
  width: 100%;
  min-height: 120vh;
  height: 100%;
  padding-bottom: 75px !important;
}
.pageContent {
  background-color: #fff;
  width: 96vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 21px;
  padding: 89px;
  font-size: 18px;
  min-height: 89vh;
  position: relative;
  top: 144px;
  margin-bottom: 75px !important;
}
button {
  font-family: Kiona-SemiBold, sans-serif !important;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.productCallToAction {
  position: fixed;
  z-index: 100;
  top: 134px;
  left: 86%;
  width: 233px;
  height: 50px;
  background: #fff;
  box-shadow: 13px 10px 8px #000;
  border-radius: 13px;
}
#logo {
    width: 8vw;
    height: 8vw;
    margin-left: auto;
    margin-right: auto;
}
#headerContainer {
    width: 100%;
    height: min-content;
    margin: 0;
    padding: 0px;
    text-align: center;
    box-shadow: 2px 3px 5px #000;
    background-color: #fff;
    z-index: 100;
    align-items: center !important;
    position: fixed;
    top: 0;
    left: 0;
}
@media screen and (max-width: 900px) {
    #logo {
        width: 21vw;
        height: 21vw;
       margin-left: auto;
       margin-right: auto;
       margin-bottom: 0px;
    }
}
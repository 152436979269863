#footer {
    position: relative;
    bottom: 0px !important;
    width: 100%;
    height: 50px;
    margin: 0 0 0 0;
    background-color: rgba(85,128,169,1);
    box-shadow: 0px -3px 5px rgba(85,128,169,0.55);
    clear: both;
    padding-top: 5px;
    color: #fff;
}
#copyright {
    font-family: Kiona-SemiBold, sans-serif !important;
}